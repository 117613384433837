@import 'style.sass'

#footer
  min-height: 20vh
  background-color: #3F334D
  padding: 2em
  padding-left: 10%
  padding-right: 10%
  display: grid
  grid-template-columns: 1fr 1fr
  grid-template-rows: auto auto auto
  color: white
  a
    color: white
    text-decoration: none
  #programs-footer
    position: relative
    #program-items-footer
      top: -1000%
      right: -80%
      text-align: center
      height: fit-content
      position: absolute
      border-radius: .25em
      width: 250%
      background-color: white
      border: 1px solid black
      .divider-line
        background-color: black
        margin: 0
      .program-item
        padding: 2em .8em
        color: #121619
        font-weight: bold
      #for-kids-footer
        border-radius: .25em .25em 0 0
      #for-adult-footer
        border-radius: 0 0 .25em .25em
      p
        margin: 0 1em
      a
        color: #121619
        font-size: .75em
      .program-item:hover
        background-color: lightgrey
      @media screen and (max-width: 850px)
        top: -450%
        right: -100%
        width: 400%
        .program-item
          padding: 1.5em
  #footer-options,.divider-line
    grid-column: 1/3
  #footer-options
    text-align: center
    align-self: center
    grid-row: 1/2
    span
      margin: 1em
      cursor: pointer
  .divider-line
    margin-top: 1em
    grid-row: 2/3
  #copyright,#socials
    font-size: .8em
    grid-row: 3/4
  #socials
    text-align: right
    align-self: center
    span
      margin-right: 1em
    .icons
      img
        width: 1.2vw
        margin-right: 0.5em
        cursor: pointer
  @media screen and (max-width: 850px)
    grid-template-columns: 100%
    grid-template-rows: auto auto auto auto
    padding-top: 0
    #footer-options,.divider-line
      grid-column: 1
    #footer-options
      span
        margin: 0
      span::before
        content: '\a'
        white-space: pre
    .divider-line
      margin-bottom: 1em
    #socials
      text-align: center
      grid-row: 3
      .icons
        span::after
          content: '\a'
          white-space: pre
        img
          width: 5vw
          margin-top: 1em
    #copyright
      text-align: center
      margin-top: 1em
      grid-row: 4
      p
        margin-bottom: .5em
