/* In this project, style.sass (this file) is used for styles that will be used repeatedly throughout the project and imported into other css files with SASS imports. DO NOT use this file to add styles that will only apply to one page of the application. Instead, create a new sass file that is particular to that page. */

// include the following line at the top of all sass files to enable autoprefixer
/* autoprefixer grid: autoplace */

@import 'vars.sass'

@font-face
  src: url('../fonts/manrope.ttf')
  font-family: 'manrope'

@font-face
  src: url('../fonts/manrope-bold.ttf')
  font-family: manrope-bold

@font-face
  src: url('../fonts/futura.otf'), url('../fonts/futura.woff') format('woff')
  font-family: 'futura-bold'

body
  font-family: manrope
  margin: 0
  font-size: 1.1em
  // width: 100vw
  color: #121619
  overflow-x: hidden
  p
    margin-top: .25em
    // line-height: 1.6

  @media screen and (max-width: 850px)
    font-size: 1em

h1,h2,h3
  font-family: futura-bold
  margin-bottom: 0
  @media screen and (max-width: 850px)
    font-size: 1.5em !important
p, span
  &.bold
    font-family: manrope-bold
.gradient-underline
  background-image: linear-gradient(to right, #F7921E, #B337FF)
  background-size: 100% 10px
  background-position: 0 100%
  background-repeat: no-repeat

a
  text-decoration: none

h1
  font-size: 3em
h2
  font-size: 2em // live change 7/15
  padding-bottom: 6px
li
  margin-top: .25em
  margin-bottom: .25em

.container
  // padding-top: 8vh
  height: fit-content
  // @media screen and (max-width: 850px)
    // padding-top: 8vh

.centered
  text-align: center

.gray-bg
  background-color: #f5f5f5

.gradient-bg
  padding: 4em 2em
  background-image: $gradient

.gradient-ul
  padding-bottom: .2em
  border-bottom: 8px solid
  border-image: linear-gradient(to right, #F7921E, #B337FF) 1

.gradient
  background-image: $gradient

// .round
//   width: 24px
//   height: 24px
//   border-radius: 50%
//   padding: 2px 4px
//   display: inline

.hide
  display: none
  visibility: hidden
.no-scroll
  overflow-y: hidden

.split
  width: 100%
  display: grid
  grid-template-columns: 50% 50%
  grid-template-rows: 100%
  padding-top: 1.5em
  padding-bottom: 2em

  .text
    justify-self: center
    align-self: center
    max-width: 80%
    box-sizing: border-box
  .image
    justify-self: center
    text-align: center
    align-self: center
    img
      width: 75%
  @media screen and (max-width: 850px)
    grid-template-columns: 100%
    grid-template-rows: auto auto
    .text
      grid-row: 1
.text .icons
  max-width: 100%
  img
    margin: 0 .2em
    // width: 2vw
    height: 30px
    width: auto
    vertical-align: middle
    @media screen and (max-width: 850px)
      height: 15px
  // .word-icon
  //   width: 5vw
  //   img
  //     width: 5vw
  //   .word-icon
  //     width: 12vw

.slide-in
  transition: .5s

.slide-out
  transition: .5s

$color-map: ("violet": $violet, "orange": $orange, "black": $black)

@mixin button($bg-color)
  .button-#{$bg-color}
    background-color: map-get($color-map, $bg-color)
    border: $thin-border solid map-get($color-map, $bg-color)
    &:hover
      background-color: scale-color(map-get($color-map, $bg-color), $lightness: -20%)
      border-color: scale-color(map-get($color-map, $bg-color), $lightness: -20%)

.button-outline
  background-color: transparent
  border: $thin-border solid white
  &.black
    border: $thin-border solid $black
    color: $black
    &:hover
      background-color: rgba(127,127,127,0.1)

  &:hover
    background-color: rgba(127,127,127,0.5)
.bold
  font-weight: 800
  font-family: manrope-bold

.bolder
  font-weight: 700

button
  border: none
  box-sizing: border-box
  padding: $btn-tb-padding $btn-lr-padding
  border-radius: $medium-border
  transition: .4s
  font-family: manrope-bold
  font-size: 1em
  // font-weight: 700
  color: white
  cursor: pointer
  &.smaller
    padding: calc(#{$btn-tb-padding} / 2) calc(#{$btn-lr-padding} / 2)
  @media screen and (max-width: 900px)
    padding: calc(#{$btn-tb-padding} / 2) calc(#{$btn-lr-padding} / 2)

.divider-line
  background-color: gray
  width: 100%
  justify-self: center
  height: 1px
