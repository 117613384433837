/* In this project, style.sass (this file) is used for styles that will be used repeatedly throughout the project and imported into other css files with SASS imports. DO NOT use this file to add styles that will only apply to one page of the application. Instead, create a new sass file that is particular to that page. */
/* autoprefixer grid: autoplace */
@font-face {
  src: url("../fonts/manrope.ttf");
  font-family: "manrope";
}
@font-face {
  src: url("../fonts/manrope-bold.ttf");
  font-family: manrope-bold;
}
@font-face {
  src: url("../fonts/futura.otf"), url("../fonts/futura.woff") format("woff");
  font-family: "futura-bold";
}
body {
  font-family: manrope;
  margin: 0;
  font-size: 1.1em;
  color: #121619;
  overflow-x: hidden;
}
body p {
  margin-top: 0.25em;
}
@media screen and (max-width: 850px) {
  body {
    font-size: 1em;
  }
}

h1, h2, h3 {
  font-family: futura-bold;
  margin-bottom: 0;
}
@media screen and (max-width: 850px) {
  h1, h2, h3 {
    font-size: 1.5em !important;
  }
}

p.bold, span.bold {
  font-family: manrope-bold;
}

.gradient-underline {
  background-image: linear-gradient(to right, #F7921E, #B337FF);
  background-size: 100% 10px;
  background-position: 0 100%;
  background-repeat: no-repeat;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2em;
  padding-bottom: 6px;
}

li {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.container {
  height: fit-content;
}

.centered {
  text-align: center;
}

.gray-bg {
  background-color: #f5f5f5;
}

.gradient-bg {
  padding: 4em 2em;
  background-image: linear-gradient(to right, #F7921E, #B337FF);
}

.gradient-ul {
  padding-bottom: 0.2em;
  border-bottom: 8px solid;
  border-image: linear-gradient(to right, #F7921E, #B337FF) 1;
}

.gradient {
  background-image: linear-gradient(to right, #F7921E, #B337FF);
}

.hide {
  display: none;
  visibility: hidden;
}

.no-scroll {
  overflow-y: hidden;
}

.split {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  padding-top: 1.5em;
  padding-bottom: 2em;
}
.split .text {
  justify-self: center;
  align-self: center;
  max-width: 80%;
  box-sizing: border-box;
}
.split .image {
  justify-self: center;
  text-align: center;
  align-self: center;
}
.split .image img {
  width: 75%;
}
@media screen and (max-width: 850px) {
  .split {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }
  .split .text {
    grid-row: 1;
  }
}

.text .icons {
  max-width: 100%;
}
.text .icons img {
  margin: 0 0.2em;
  height: 30px;
  width: auto;
  vertical-align: middle;
}
@media screen and (max-width: 850px) {
  .text .icons img {
    height: 15px;
  }
}

.slide-in {
  transition: 0.5s;
}

.slide-out {
  transition: 0.5s;
}

.button-outline {
  background-color: transparent;
  border: 2px solid white;
}
.button-outline.black {
  border: 2px solid #000000;
  color: #000000;
}
.button-outline.black:hover {
  background-color: rgba(127, 127, 127, 0.1);
}
.button-outline:hover {
  background-color: rgba(127, 127, 127, 0.5);
}

.bold {
  font-weight: 800;
  font-family: manrope-bold;
}

.bolder {
  font-weight: 700;
}

button {
  border: none;
  box-sizing: border-box;
  padding: 0.6rem 2rem;
  border-radius: 5px;
  transition: 0.4s;
  font-family: manrope-bold;
  font-size: 1em;
  color: white;
  cursor: pointer;
}
button.smaller {
  padding: calc(0.6rem / 2) calc(2rem / 2);
}
@media screen and (max-width: 900px) {
  button {
    padding: calc(0.6rem / 2) calc(2rem / 2);
  }
}

.divider-line {
  background-color: gray;
  width: 100%;
  justify-self: center;
  height: 1px;
}

#footer {
  min-height: 20vh;
  background-color: #3F334D;
  padding: 2em;
  padding-left: 10%;
  padding-right: 10%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  color: white;
}
#footer a {
  color: white;
  text-decoration: none;
}
#footer #programs-footer {
  position: relative;
}
#footer #programs-footer #program-items-footer {
  top: -1000%;
  right: -80%;
  text-align: center;
  height: fit-content;
  position: absolute;
  border-radius: 0.25em;
  width: 250%;
  background-color: white;
  border: 1px solid black;
}
#footer #programs-footer #program-items-footer .divider-line {
  background-color: black;
  margin: 0;
}
#footer #programs-footer #program-items-footer .program-item {
  padding: 2em 0.8em;
  color: #121619;
  font-weight: bold;
}
#footer #programs-footer #program-items-footer #for-kids-footer {
  border-radius: 0.25em 0.25em 0 0;
}
#footer #programs-footer #program-items-footer #for-adult-footer {
  border-radius: 0 0 0.25em 0.25em;
}
#footer #programs-footer #program-items-footer p {
  margin: 0 1em;
}
#footer #programs-footer #program-items-footer a {
  color: #121619;
  font-size: 0.75em;
}
#footer #programs-footer #program-items-footer .program-item:hover {
  background-color: lightgrey;
}
@media screen and (max-width: 850px) {
  #footer #programs-footer #program-items-footer {
    top: -450%;
    right: -100%;
    width: 400%;
  }
  #footer #programs-footer #program-items-footer .program-item {
    padding: 1.5em;
  }
}
#footer #footer-options, #footer .divider-line {
  grid-column: 1/3;
}
#footer #footer-options {
  text-align: center;
  align-self: center;
  grid-row: 1/2;
}
#footer #footer-options span {
  margin: 1em;
  cursor: pointer;
}
#footer .divider-line {
  margin-top: 1em;
  grid-row: 2/3;
}
#footer #copyright, #footer #socials {
  font-size: 0.8em;
  grid-row: 3/4;
}
#footer #socials {
  text-align: right;
  align-self: center;
}
#footer #socials span {
  margin-right: 1em;
}
#footer #socials .icons img {
  width: 1.2vw;
  margin-right: 0.5em;
  cursor: pointer;
}
@media screen and (max-width: 850px) {
  #footer {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
    padding-top: 0;
  }
  #footer #footer-options, #footer .divider-line {
    grid-column: 1;
  }
  #footer #footer-options span {
    margin: 0;
  }
  #footer #footer-options span::before {
    content: "\a";
    white-space: pre;
  }
  #footer .divider-line {
    margin-bottom: 1em;
  }
  #footer #socials {
    text-align: center;
    grid-row: 3;
  }
  #footer #socials .icons span::after {
    content: "\a";
    white-space: pre;
  }
  #footer #socials .icons img {
    width: 5vw;
    margin-top: 1em;
  }
  #footer #copyright {
    text-align: center;
    margin-top: 1em;
    grid-row: 4;
  }
  #footer #copyright p {
    margin-bottom: 0.5em;
  }
}