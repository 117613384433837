// Colors
$purple: #B337FF
$orange: #F7921E
$neon: #E2FF4F
$violet: #3F334D
$black: #000000
$blue: #00BFFF
$lightgrey: #f7f7f7
$mediumgrey: #DDD
$darkgrey: #333
$gradient: linear-gradient(to right, #{$orange}, #{$purple})

// Padding
$btn-tb-padding: .6rem
$btn-lr-padding: 2rem
$content-gutter: 2rem

// Other
$thin-border: 2px
$medium-border: 5px
$nav-height: 5.5vh
$shadow: -2px 2px 1px #eee, 2px -2px 1px #eee, 2px 2px 1px #eee, -2px -2px 1px #eee
